const getters = {
  loading: (state) => state.loading,
  categories: (state) => state.categories,
  cartActiveStatus: (state) => state.cartActiveStatus,
  moboMenuActiveStatus: (state) => state.mbmActiveStatus,
  shoppingBag: (state) => state.shoppingBag,
  isExist: (state) => (id) => {
    return state.shoppingBag.find((cart) => cart.id === id);
  },
  prodQty: (state) => (id) => {
    let prodQty = state.shoppingBag.find((cart) => cart.id === id);
    return prodQty.qty;
  },
  total(state) {
    return state.shoppingBag.reduce((a, b) => a + (b["amount"] || 0), 0);
  },
  preCountGroupProduct: (state) => (groupProductId) => {
    let count = 0;
    let totalQuantity = 0;

    if (groupProductId != null) {
      state.shoppingBag.forEach((item) => {
        if (item.group_product_id === groupProductId) {
          count++;
          // totalQuantity += this.prodQty(item.id);
          totalQuantity += state.shoppingBag.find(
            (cart) => cart.id === item.id
          ).qty;
        }
      });
    }
    // Return total quantity only if there are multiple products in the group
    return count > 0 ? totalQuantity : 0;
  },
  countGroupProduct: (state) => (groupProductId) => {
    let count = 0;
    let totalQuantity = 0;
    if (groupProductId != null) {
      state.shoppingBag.forEach((item) => {
        if (item.group_product_id === groupProductId) {
          count++;
          // totalQuantity += this.prodQty(item.id);
          totalQuantity += state.shoppingBag.find(
            (cart) => cart.id === item.id
          ).qty;
        }
      });
    }

    // Return total quantity only if there are multiple products in the group
    return count > 1 ? totalQuantity : 0;
  },
  updateGroupProduct: (state) => (groupProductId, price) => {
    if (groupProductId != null) {
      state.shoppingBag.forEach((item) => {
        if (item.group_product_id === groupProductId) {
          item.unitPrice = price;
          item.amount = item.qty * price;
        }
      });
    }
  },
  sitUrl: (state) => state.siteUrl,
  nextPath: (state) => (state.siteUrl !== null ? state.siteUrl.table : ""),
  category: (state) => state.category,
  siteSettings: (state) => state.siteSettings,
  siteSetting: (state) => (slug) => {
    return state.siteSettings.find((setting) => setting.slug === slug);
  },
  brand: (state) => state.brand,
  brands: (state) => state.brands,
  brandsByTextFilter: (state) => state.brandsByTextFilter,
  categoryByTextFilter: (state) => state.categoryByTextFilter,
  productBySearch: (state) => state.productBySearch,
  brandFilterForHome: (state) => state.brandsByTextFilterHome,
  productForSearchPage: (state) => state.productForSearchPage,
  locations: (state) => state.locations,
  brandCateory: (state) => state.brand.categories,

  productDetails: (state) => state.productDetails,
  productPrice: (state) => {
    if (state.productDetails.product_type === 2) {
      const product = state.productDetails.groupProducts.map(
        (gp) => gp.product
      );
      const psArr = [];
      product.map((ps) => {
        ps.product_prices.map((pp) => {
          psArr.push(pp.price);
        });
      });
      console.log("-------------------111111");
      return `${Math.min(...psArr)} TK - ${Math.max(...psArr)}`;
    } else {
      console.log("-------------------2222");
      return state.productDetails.productPrice;
    }
  },
  productColor: (state) => {
    if (state.productDetails.product_type === 2) {
      const product = state.productDetails.groupProducts.map(
        (gp) => gp.product
      );
      return product.map((prod) => {
        prod.product_color.product_id = prod.id;
        return prod;
        // return prod.product_color;
      });
    } else {
      return state.productDetails.product_color;
    }
  },
  productSize: (state) => {
    if (state.productDetails.product_type === 2) {
      const product = state.productDetails.groupProducts.map(
        (gp) => gp.product
      );
      return product.map((prod) => {
        prod.product_size.product_id = prod.id;
        return prod;
        // return prod.product_size;
      });
    } else {
      return state.productDetails.product_size;
    }
  },
  productImage: (state) => (type) => {
    return state.productDetails.product_images.map((img) => {
      return {
        id: img.id,
        url: img[type],
      };
    });
  },
  zoomeImage: (state) => state.zoomeImage,
  ReletedProduct: (state) =>
    state.productDetails.releted_product.map((rp) => rp.product),
  home: (state) => state.home,
  topProduct: (state) => (position) => {
    return state.home.promoAds.find((pa) => pa.position_in_column === position);
  },
  paymentMethods: (state) => {
    return state.siteSettings.find((setting) => setting.id === 6);
  },
  saleNow: (state) => state.saleNow,
  topSelling: (state) => state.topSelling,
  customer: (state) => state.customer,
  wishlist: (state) => state.customer.wishlist,
  accessToken: (state) => state.customer && state.customer.token,
  user: (state) => state.user,
  payment: (state) => state.payment,
  me: (state) => state.me,
  customerSummery: (state) => state.customerSummery,
  customerPoints: (state) => state.customerPoint,
  customer_wishlist: (state) => state.customerWishlist,
  priceSearchValue: (state) => state.priceSearchVal,
  shippingCharge: (state) => state.shippingCharge,
  getPage: (state) => state.page,
  orderDetails: (state) => state.orderDetails,
  orderCancel: (state) => state.orderCancel,
  singleProduct: (state) => state.singleProduct,
  loadMorePage: (state) => state.loadMorePage,
  newsletterres: (state) => state.newsletter,
  getPurchaseItems: (state) => state.purchaseItems,
};

export default getters;
