const state = {
  loading: false,
  categories: [],
  locations: [],
  siteUrl: null,
  category: null,
  brand: null,
  brands: [],
  brandsByTextFilter: [],
  categoryByTextFilter: [],
  productBySearch: [],
  productForSearchPage: [],
  productDetails: null,
  siteSettings: [],
  home: [],
  saleNow: [],
  topSelling: [],
  customer: null,
  wishlist: null,
  user: [],
  payment: [],
  me: null,
  customerSummery: [],
  customerPoint: [],
  customerWishlist: [],
  shoppingBag:[],
  zoomeImage:{},
  cartActiveStatus: false,
  mbmActiveStatus: false,
  priceSearchVal :'',
  brandsByTextFilterHome :[],
  page:1,
  shippingCharge:{},
  orderDetails:[],
  orderCancel:'',
  singleProduct:[],
  loadMorePage:1,
  newsletter:{},
  purchaseItems:[],
};
export default state;
