import Vue from "vue";
import VueRouter from "vue-router";
//import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: "/dashboard",
    component: () => import("@/layouts/dashboard/Main.vue"),
    children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
      {
        path: "/",
        name: "dashboard",
        component: () => import("@/views/dashboard/Dashboard.vue"),
      },
    ],
  },
  {
    path: "/test-page",
    name: "testpage",
    component: () => import("@/views/TestPage.vue"),
    meta: {
      requiresGuest: true,
    },
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: "/customer",
    component: () => import("@/layouts/frontend/CustomerPage.vue"),
    children: [
      {
        path: "/",
        name: "login",
        component: () => import("@/views/frontend/Login.vue"),
        meta: {
          requiresGuest: true,
        },
      },
      {
        path: "/registration",
        name: "registration",
        component: () => import("@/views/frontend/Registration.vue"),
        meta: {
          requiresGuest: true,
        },
      },
    ],
  },
  {
    path: "/customer-dashboard",
    component: () => import("@/layouts/frontend/CustomerPage.vue"),
    children: [
      {
        path: "/",
        name: "customer_dashboard",
        component: () => import("@/views/frontend/Dashboard.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/mywishlist",
        name: "mywishlist",
        component: () => import("@/views/frontend/MyWishlist.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/pointHistory",
        name: "pointHistory",
        component: () => import("@/views/frontend/PointHistory.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/accountInformation",
        name: "accountInformation",
        component: () => import("@/components/frontend/MyDashboard/AccountInformation.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/orderInformation",
        name: "orderInformation",
        component: () => import("@/components/frontend/MyDashboard/Ordered.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/order_details/:orderId",
        name: "order-details",
        component: () => import("@/components/frontend/MyDashboard/OrderDetails.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/orderFromHistory",
        name: "orderFromHistory",
        component: () => import("@/components/frontend/MyDashboard/OrderedFromHistory.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/checkout",
        name: "checkout",
        component: () => import("@/views/frontend/Payment.vue"),
        meta: {
          //requiresAuth: true,
        },
      },
      {
        path: "/purchase/:status/:message",
        name: "purchase",
        component: () => import("@/views/frontend/ConfirmPayment.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/wishlist",
        name: "wishlist",
        component: () => import("@/views/frontend/Wishlist.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "",
    component: () => import("@/layouts/frontend/FullPage.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/frontend/Home.vue"),
      },
      {
        path: "/cart-list",
        name: "cartlist",
        component: () => import("@/views/frontend/CartList.vue"),
      },
      {
        path: "category/:slug",
        name: "category",
        component: () => import("@/views/frontend/Category.vue"),
      },
      {
        path: "brand/:slug",
        name: "brand",
        component: () => import("@/views/frontend/Brand.vue"),
      },
      {
        path: "product/:slug/:type",
        name: "product",
        component: () => import("@/views/frontend/Details.vue"),
      },
      {
        path: "search/:searchValue",
        name: "search",
        component: () => import("@/views/frontend/Search.vue"),
      },
      {
        path: "/pages/error-404",
        name: "page-error-404",
        component: () => import("@/views/pages/Error404.vue"),
      },
      {
        path: "/:slug",
        name: "pages",
        component: () => import("@/views/pages/termsandcondition.vue"),
      },
      {
        path: '/pages/all-social-events',
        component: () => import("@/views/pages/SocialEvents.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: '/callback/socialuserinfo',
        component: {
          template: '<div class="auth-component"></div>'
        }
      },
    ],
  },
  {
    path: "*",
    redirect: "/pages/error-404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if('slug' in to.params){
      if(from.params.slug === to.params.slug){
        return null;
      }
    }else {
      console.log( savedPosition );
      return { x: 0, y: 0 };
    }

  },
  routes,
});

/*router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  /!*if (appLoading) {
    appLoading.style.display = "none";
  }*!/
});*/

// router.beforeEach((to, from, next) => {
//   // Check for requiresAuth guard
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // Check if NO logged user
//     if (!store.getters.accessToken) {
//       // Go to login
//       next({
//         path: "/customer/",
//         query: {
//           redirect: to.fullPath,
//         },
//       });
//     } else {
//       // Proceed to route
//       next();
//     }
//   } else if (to.matched.some((record) => record.meta.requiresGuest)) {
//     // Check if NO logged user
//     if (store.getters.accessToken) {
//       // Go to login
//       next({
//         path: "/",
//         query: {
//           redirect: to.fullPath,
//         },
//       });
//     } else {
//       // Proceed to route
//       next();
//     }
//   } else {
//     // Proceed to route
//     next();
//   }
// });

export default router;
