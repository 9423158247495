// axios
import axios from "axios";
// import store from "./store"
// import router from "./router"
// Vuex Store
//const baseURL = "http://13.232.254.4/eurasia-backend/api";
//const baseURL = "http://43.204.54.58/eurasia-backend/api";
//const baseURL = "https://savershall.com/eurasia-backend/api";
const baseURL = "https://savershall.com/sh_backapi/api";

export default axios.create({
  baseURL,
  timeout: 100000,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
  },
  // You can add your headers here
});
