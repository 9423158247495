import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ProductZoomer from "vue-product-zoomer";
import vSelect from 'vue-select';
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
import 'vue-range-component/dist/vue-range-slider.css'
import vClickOutside from 'v-click-outside'
import VueMeta from 'vue-meta'
Vue.use(VueMeta);
Vue.use(vClickOutside);
import Toasted from 'vue-toasted';
Vue.use(Toasted,{
  duration:1500,
  position:'top-right',
  theme:'toasted-primary'
});
import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
//       //app id: 1265910360666082
//       //secret : 9fbfae8af893800f51957bbbfbeee53f
// import VeeValidate from 'vee-validate';
//
// Vue.use(VeeValidate);
import { ValidationProvider, extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  min,
  max,
  confirmed,
  length,
} from "vee-validate/dist/rules";
Vue.directive('click-outside', {
  bind: function (element, binding, vnode) {
    element.clickOutsideEvent = function (event) {  //  check that click was outside the el and his children
      if (!(element === event.target || element.contains(event.target))) { // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
        // binding.value(); run the arg
      }
    };
    document.body.addEventListener('click', element.clickOutsideEvent)
  },
  unbind: function (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent)
  }
});
import SmartTable from 'vuejs-smart-table'

Vue.use(SmartTable)
Vue.config.productionTip = false;

Vue.use(ProductZoomer);

Vue.component("ValidationProvider", ValidationProvider);

//extend('email', email);
extend("required", {
  ...required,
  message: "{_field_} is required",
});
extend("email", {
  ...email,
  message: "Email is not Validated",
});

extend("numeric", {
  ...numeric,
  message: "Only number allow",
});
extend("min", {
  ...min,
  message: "Minimum 6 Charecters",
});
extend("max", {
  ...max,
  message: "Maximum 12 Charecters",
});
extend("confirmed", {
  ...confirmed,
  message: "Password Do Not Match",
});
extend("length", {
  ...length,
  message: "Phone Number Must Be 11 Digits",
});
extend("minName", {
  ...min,
  message: "Name More than 2 Charecters",
});
let filter = function(text, length, clamp){
  clamp = clamp || '...';
  let node = document.createElement('div');
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter('truncate', filter);
new Vue({
  router,
  store,
  ProductZoomer,
  created() {
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getSiteSettings");
  },
  render: (h) => h(App),
}).$mount("#app");